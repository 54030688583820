import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { GatsbyImage } from "gatsby-plugin-image";

const TeamMemberCard = ({ img, imgAlt, name, job, text, hiddenImg }) => {
	return (
		<Col lg={6}>
			<Card
				style={{
					boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
					overflow: "hidden",
				}}
				className="border-0  team-image my-4 mx-0"
			>
				<div className=" position-relative">
					<GatsbyImage
						Tag=""
						className="team-image__image--visible position-relative  team-image__image"
						image={img}
						alt={imgAlt}
					></GatsbyImage>
					<GatsbyImage
						Tag=""
						className="team-image__image--hidden position-absolute h-100 w-100 start-0 top-0 team-image__image"
						image={hiddenImg}
						alt={imgAlt}
					></GatsbyImage>
				</div>
				<Card.Header className="bg-secondary border-0 p-4 pb-3 text-white">
					<Card.Title className="montserrat-semi-bold mb-0 mb-md-1 mb-lg-0">
						{name}
					</Card.Title>
					<hr
						style={{ height: "2px", opacity: 1 }}
						className="bg-primary w-30"
					/>
					<p>{job}</p>
				</Card.Header>
				<Card.Body className="p-4">
					<Row>
						<Col>
							<Card.Text>
								<p>{text}</p>
							</Card.Text>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default TeamMemberCard;
