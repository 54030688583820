import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import TeamMemberCard from "../../components/team-member-card";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import BeeBanner from "../../components/bee-banner";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import SVGFBWwa from "../../components/SVG/flying-bee-wwa";

const WhoWeAre = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "_DSC3943" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gemmaBaxterImg: wpMediaItem(title: { eq: "Gemma-Baxter-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			kevinBaxterImg: wpMediaItem(title: { eq: "Kevin-Baxter-Image-New" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			JenImg: wpMediaItem(title: { eq: "Jen-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			EmmaImg: wpMediaItem(title: { eq: "Emma-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			teamMemberImg: wpMediaItem(title: { eq: "_DSC3869" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			teamMemberImgHidden: wpMediaItem(title: { eq: "_DSC3883" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			EmmaImgHidden: wpMediaItem(title: { eq: "Emma-Image-Hidden" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			JenImgHidden: wpMediaItem(title: { eq: "Jen-Image-Hidden" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			kevinBaxterImgHidden: wpMediaItem(
				title: { eq: "Kevin-Baxter-Image-Hidden-New" }
			) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gemmaBaxterImgHidden: wpMediaItem(
				title: { eq: "Gemma-Baxter-Image-Hidden" }
			) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			fionaImg: wpMediaItem(title: { eq: "Fiona-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			fionaImgHidden: wpMediaItem(title: { eq: "Fiona-Image-Hidden" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			lucyImg: wpMediaItem(title: { eq: "Lucy-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			lucyImgHidden: wpMediaItem(title: { eq: "Lucy-Image-Hidden" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			raieImg: wpMediaItem(title: { eq: "Raie-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			raieImgHidden: wpMediaItem(title: { eq: "Raie-Image-Hidden" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg.localFile.childImageSharp.gatsbyImageData;
	const gemmaBaxterImg =
		data.gemmaBaxterImg.localFile.childImageSharp.gatsbyImageData;
	const gemmaBaxterImgHidden =
		data.gemmaBaxterImgHidden.localFile.childImageSharp.gatsbyImageData;

	const kevinBaxterImg =
		data.kevinBaxterImg.localFile.childImageSharp.gatsbyImageData;
	const kevinBaxterImgHidden =
		data.kevinBaxterImgHidden.localFile.childImageSharp.gatsbyImageData;

	const JenImg = data.JenImg.localFile.childImageSharp.gatsbyImageData;
	const JenImgHidden =
		data.JenImgHidden.localFile.childImageSharp.gatsbyImageData;

	const EmmaImg = data.EmmaImg.localFile.childImageSharp.gatsbyImageData;
	const EmmaImgHidden =
		data.EmmaImgHidden.localFile.childImageSharp.gatsbyImageData;

	const teamMemberImg =
		data.teamMemberImg.localFile.childImageSharp.gatsbyImageData;
	const teamMemberImgHidden =
		data.teamMemberImgHidden.localFile.childImageSharp.gatsbyImageData;

	const fionaImg = data.fionaImg.localFile.childImageSharp.gatsbyImageData;
	const fionaImgHidden =
		data.fionaImgHidden.localFile.childImageSharp.gatsbyImageData;
	const lucyImg = data.lucyImg.localFile.childImageSharp.gatsbyImageData;
	const lucyImgHidden =
		data.lucyImgHidden.localFile.childImageSharp.gatsbyImageData;
	const raieImg = data.raieImg.localFile.childImageSharp.gatsbyImageData;
	const raieImgHidden =
		data.raieImgHidden.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Who We Are",
				item: {
					url: `${siteUrl}/who-we-are`,
					id: `${siteUrl}/who-we-are`,
				},
			},
		],
	};

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="Who We Are | Hive HR Solutions"
					description="Hive HR Solutions was founded in 2017 as a small company with big ambitions: to shake up the HR consultancy world by providing a personalised human resources support service. / placeholder"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/who-we-are`,
						title: "Who We Are | Hive HR Solutions",
						description:
							"Hive HR Solutions was founded in 2017 as a small company with big ambitions: to shake up the HR consultancy world by providing a personalised human resources support service. / placeholder",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>

				<section
					id="introduction"
					className="py-5 position-relative py-md-8 py-lg-9"
				>
					<SVGFBWwa
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top-10 d-none d-lg-block "
					/>
					<Container>
						<Row>
							<Col>
								<div className="text-center">
									<h1 className="display-3">Who we are</h1>
									<hr className="hr-primary mx-auto w-50 mb-4" />
									<h2 className="fs-1 pb-4 fw-normal">
										Meet our team of HR experts for SMEs{" "}
									</h2>
								</div>
								<GatsbyImage
									className=" w-100  "
									image={heroImg}
									alt={data.heroImg?.altText}
								></GatsbyImage>
								<p className="pt-4">
									Hive HR Solutions was founded in 2017 as a small company with
									big ambitions: to shake up the HR consultancy world by
									providing a personalised human resources service.
								</p>
								<p>
									Ditching the templates and 'one size fits all' approach, we
									set out from the outset to go that extra mile by offering
									bespoke human resources management solutions to companies that
									recognise the value HR can add to their company in terms of
									employee development, retention and engagement.
								</p>
								<p>
									Since 2017, we have grown our team of HR professionals, each
									with different but complementary skills, capable of offering a
									full suite of human resources support packages tailored to
									clients' needs, budgets and industry. From long-term packages
									aimed at supporting clients on an ongoing basis to a block of
									hours to proactively tackle employee issues and avoid costly
									disputes that can arise when HR is approached reactively. 
								</p>
								<p>
									{" "}
									We work closely with our clients to help them grow their
									business and achieve their goals, safe in the knowledge that
									their human resources needs are being anticipated in advance
									and handled by a safe pair of hands, leaving our clients free
									to spend their time more efficiently.
								</p>
								<p>
									{" "}
									We are passionate about consistently providing a first-class
									service for a reasonable price and becoming a key partner for
									our clients. Our core values underpin everything we do and are
									based on exceeding clients' expectations and operating with
									integrity and professionalism in every aspect of our work.{" "}
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="mb-5">
					<Container>
						<Row>
							<Col>
								<h2 className="fs-1 pb-4">Team members</h2>
								<hr className="hr-primary w-40 mb-4" />
							</Col>
						</Row>
						<Row className="gx-6">
							<TeamMemberCard
								hiddenImg={gemmaBaxterImgHidden}
								img={gemmaBaxterImg}
								imgAlt={data.gemmaBaxterImg?.altText}
								name="Gemma Baxter"
								job="Owner and Founder"
								text="Gemma’s HR career started 20 years ago and has spanned various industries across Creative Arts, Engineering, Technology and Financial Services.  Gemma has extensive experience in employee relations, project management and learning and development.  She is passionate about the business and our clients, she has an approachable style, is down to earth and has a wicked laugh. When she isn’t working, Gemma likes spending time with her husband and friends."
							/>
							<TeamMemberCard
								hiddenImg={kevinBaxterImgHidden}
								img={kevinBaxterImg}
								imgAlt={data.kevinBaxterImg?.altText}
								name="Kevin Baxter"
								job="Business Development Manager"
								text="Kevin has previous experience of working in hospitality management, recruitment and people management. Kevin conducts a lot of research for us, manages our back office and develops more efficient, streamlined ways of working. He is the calm one and he has a great sense of humour."
							/>

							<TeamMemberCard
								hiddenImg={EmmaImgHidden}
								img={EmmaImg}
								imgAlt={data.EmmaImg?.altText}
								name="Emma Rose"
								job="HR Business Partner"
								text="Emma's career has been developed through various exciting industries, including music, care, retail, and the private education sector. She is passionate about customer delivery, has a keen eye for detail and a pragmatic and supportive approach to providing HR Solutions. When not giving her all to her profession, Emma can be found listening to music, watching films with her partner and spending time with her pets, family and friends."
							/>
							<TeamMemberCard
								hiddenImg={teamMemberImgHidden}
								img={teamMemberImg}
								imgAlt={data.teamMemberImg?.altText}
								name="Rob Evans"
								job="HR Business Partner"
								text="Rob is a qualified CIPD professional with extensive experience of working in both retail operations and various HR roles. Having spent the first part of his career in retail, Rob then enjoyed time in food wholesale, corporate dental and the private healthcare sector. He then moved into HR consultancy and now has approximately 13 years of experience in this sector, supporting and advising clients in all areas of HR. Rob is passionate about providing great customer service and coaching line managers in the art of people management. Rob is married and has two sons and a cat. In his spare time, Rob can usually be found on two wheels, with or without an engine, or in his garage maintaining his ‘fleet’."
							/>
							<TeamMemberCard
								hiddenImg={fionaImgHidden}
								img={fionaImg}
								imgAlt={data.fionaImg?.altText}
								name="Fiona Wayne"
								job="HR Business Partner"
								text="Fiona is one of our HR Business Partners with extensive experience across a broad range of industries including retail, security and financial services. She is highly organised and loves a spreadsheet or two! Fiona has a sunshine personality, which is reflected in her approachable and friendly manner. She is a keen cook/baker and is happy to talk about food all day if required."
							/>
							<TeamMemberCard
								hiddenImg={lucyImgHidden}
								img={lucyImg}
								imgAlt={data.lucyImg?.altText}
								name="Lucy Vanson"
								job="HR Advisor"
								text="Lucy is an experienced HR Advisor with over 15 years experience spent working across financial services, telecommunication and recruitment sectors. She has a pragmatic but sensitive approach and plenty of experience providing performance management support and advice, supporting large scale restructures, designing and implementing health and wellbeing initiatives, and designing user friendly HR policies and guidance documents. Outside of work, Lucy enjoys a busy family life with her husband and four young children. She enjoys the outdoor life and when she is not freezing on the side of a football pitch she can often be found walking her dog in the beautiful Sussex countryside."
							/>
							<TeamMemberCard
								hiddenImg={raieImgHidden}
								img={raieImg}
								imgAlt={data.raieImg?.altText}
								name="Raie Wilson"
								job="HR Advisor"
								text="Raie’s career was transitional into general HR through various industries, including Pensions, Civil Engineering, Oil and Gas, Financial Services and Medical Imaging, and organisations of all sizes.  She has over 15 years HR experience with a broad range of skills picked up from inhouse HR and consultancy roles. Her passion is offering the best support and advice to colleagues and customers, with a particular interest and experience of neuro diversity. Raie is married and has two very active boys that are neuro-divergent. In her spare time, Raie is a Swimming Instructor who is keen to develop water confidence, safety and skills to swimmers of all ages and abilities. Raie also volunteers at a local Scout group, providing 1-2-1 support to children with additional needs."
							/>
						</Row>
					</Container>
				</section>
				<section>
					{" "}
					<BeeBanner
						headline="Ready to speak to us?"
						btnTxt="Call us"
						btnLink="tel:+441403627630"
					/>
				</section>
			</Layout>
		);
	}
};

export default WhoWeAre;
